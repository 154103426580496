var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","margin":"0"}},[(_vm.isAuthenticated)?_c('div',[(_vm.isEnrolled)?_c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile'),(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper",attrs:{"id":"kt_wrapper"}},[_c('KTHeader'),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid",attrs:{"id":"kt_content"}},[(_vm.subheaderDisplay && _vm.displaySubheaderOnDashboard)?_c('KTSubheader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.pageTitle}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{class:{
                'container-fluid': _vm.contentFluid,
                container: !_vm.contentFluid,
              }},[_c('div',{staticClass:"d-lg-flex flex-row-fluid"},[(_vm.asideEnabled)?_c('KTAside'):_vm._e(),_c('div',{staticClass:"content-wrapper flex-row-fluid"},[_c('v-app',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)],1)],1)])])],1),_c('KTFooter'),_c('TechnicalSupportFloatingBtn')],1)]),(_vm.toolbarDisplay)?_c('KTStickyToolbar'):_vm._e()],1):_vm._e(),(_vm.isFromAdmission && !_vm.isEnrolled)?_c('div',{staticClass:"d-flex flex-column flex-root"},[(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-column-fluid"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"padding":"0 !important"},attrs:{"cols":"12"}},[_c('div',{staticClass:"test-color py-2 d-flex"},[_c('v-container',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"},attrs:{"cols":"12"}},[_c('div',{staticClass:"mr-4"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('img',{staticStyle:{"width":"100% !important","max-height":"40px"},attrs:{"alt":"Logo","src":_vm.layoutConfig('self.logo.default')}})])],1),_c('v-btn',{on:{"click":_vm.onLogout}},[_vm._v(" Cerrar sesión ")])],1)],1)],1)],1)])],1)],1),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid",attrs:{"id":"kt_content"}},[_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{class:{
                'container-fluid': _vm.contentFluid,
                container: !_vm.contentFluid,
              }},[_c('div',{staticClass:"d-lg-flex flex-row-fluid"},[(_vm.asideEnabled)?_c('KTAside'):_vm._e(),_c('div',{staticClass:"content-wrapper flex-row-fluid"},[_c('v-app',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)],1)],1)])])]),_c('KTFooter'),_c('TechnicalSupportFloatingBtn')],1)],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }