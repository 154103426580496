<template>
  <div style="height: 100%;margin: 0;">

    <div v-if="isAuthenticated">
      <div class="d-flex flex-column flex-root" v-if="isEnrolled">
        <!-- begin:: Header Mobile -->
        <KTHeaderMobile></KTHeaderMobile>
        <!-- end:: Header Mobile -->
  
        <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
  
        <div class="d-flex flex-row flex-column-fluid page">
          <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
            <!-- begin:: Header -->
            <KTHeader></KTHeader>
            <!-- end:: Header -->
  
            <!-- begin:: Content -->
            <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
              <!-- begin:: Content Head -->
              <KTSubheader v-if="subheaderDisplay && displaySubheaderOnDashboard" v-bind:breadcrumbs="breadcrumbs"
                v-bind:title="pageTitle" />
              <!-- end:: Content Head -->
  
              <!-- begin:: Content Body -->
              <div class="d-flex flex-column-fluid">
                <div :class="{
                  'container-fluid': contentFluid,
                  container: !contentFluid,
                }">
                  <div class="d-lg-flex flex-row-fluid">
                    <!-- begin:: Aside Left -->
                    <KTAside v-if="asideEnabled"></KTAside>
                    <!-- end:: Aside Left -->
                    <div class="content-wrapper flex-row-fluid">
                      <v-app>
                        <transition name="fade-in-up">
                          <router-view />
                        </transition>
                      </v-app>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <KTFooter></KTFooter>
            <TechnicalSupportFloatingBtn></TechnicalSupportFloatingBtn>
          </div>
        </div>
  
        <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
        <!-- <KTScrollTop></KTScrollTop> -->
      </div>
  
  
      <div class="d-flex flex-column flex-root" v-if="isFromAdmission && !isEnrolled">
  
        <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
  
        <div class="d-flex flex-column flex-column-fluid">
  
          <!-- begin:: Header -->
          <v-container fluid>
            <v-row>
              <v-col cols="12" style="padding:0 !important">
            
                  <div class="test-color py-2 d-flex">
                    
                      <v-container>
                        <v-row>
                          <v-col cols="12" style="display: flex; align-items: center; justify-content: space-between;">
                            <!--begin::Logo-->
                        <div class="mr-4">
                          <router-link to="/dashboard">
                            <img alt="Logo" :src="layoutConfig('self.logo.default')" style="width: 100% !important; max-height: 40px;" />
                          </router-link>
                        </div>
                        <!--end::Logo-->
                        <!-- <div style="border: 2px solid blue;">
                          <KTTopbar></KTTopbar>
                          
                        </div> -->
  
                        <v-btn @click="onLogout">
                          Cerrar sesión
                        </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
  
                  </div>
                
              </v-col>
            </v-row>
          </v-container>
          <!-- end:: Header -->
  
          <!-- begin:: Content -->
          <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
  
            <div class="d-flex flex-column-fluid">
                <div :class="{
                  'container-fluid': contentFluid,
                  container: !contentFluid,
                }">
                  <div class="d-lg-flex flex-row-fluid">
                    <!-- begin:: Aside Left -->
                    <KTAside v-if="asideEnabled"></KTAside>
                    <!-- end:: Aside Left -->
                    <div class="content-wrapper flex-row-fluid">
                      <v-app>
                        <transition name="fade-in-up">
                          <router-view />
                        </transition>
                      </v-app>
                    </div>
                  </div>
                </div>
              </div>
          </div>
  
          <KTFooter></KTFooter>
          <TechnicalSupportFloatingBtn></TechnicalSupportFloatingBtn>
  
        </div>
  
        <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
        <!-- <KTScrollTop></KTScrollTop> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import TechnicalSupportFloatingBtn from "@/view/components/TechnicalSupportFloatingBtn";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTTopbar,
    KTHeader,
    KTHeaderMobile,
    KTSubheader,
    KTFooter,
    KTStickyToolbar,
    KTScrollTop,
    TechnicalSupportFloatingBtn,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then((data) => {
        this.sweetAlertResponse(data);
        this.fireToast({
          icon: data.status ? "success" : "error",
          title: data.message,
        });
        if (data.status) {
          this.$router.push({ name: "login" });
        }
      });
      sessionStorage.clear();
    },
    footerLayout(type) {
      return this.layoutConfig("footer.layout") === type;
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "currentUserPersonalInfo"
    ]),

    // Variable para ocultar menús si es de nuevo ingreso o tiene proceso de matrícula pendiente
    isFromAdmission() {
      return this.currentUserPersonalInfo.is_from_admission || this.currentUserPersonalInfo.is_enrolled == false
    },

    isEnrolled() {
      return this.currentUserPersonalInfo.is_enrolled;
    },

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    /**
     * Set the subheader display on dashboard page
     * @returns {boolean}
     */
    displaySubheaderOnDashboard() {
      return this.$route.name !== "file";
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.test-color {
  background-color: #263238 !important;
}

.v-application code {
  box-shadow: none !important;
}

// Table
table thead tr th {
  font-size: 1rem !important;
}

table.v-data-table {
  max-width: 100% !important;
}

table.v-table tbody td {
  font-size: 1rem !important;
}

.v-data-table__wrapper .text-start {
  white-space: nowrap !important;
}

.v-data-footer {
  font-size: 0.95rem !important;
}

// Card
.card-label {
  font-size: 1.4rem !important;
}

.card-label span {
  font-size: 1.05rem;
}

.flex-root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.page-content {
  flex: 1;
}

.footer {
  /* Your footer styles */
}
</style>
